@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-supplier {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .content {
    padding: 16px;
    a {
      color: black;
    }
    .supplier-card {
      margin-bottom: 16px;
      margin-top: 16px;
      max-width: 300px;
      .supplier-characteristic {
        .label {
          font-weight: bold;
        }
      }
    }
    .capabilities {
      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
    .capabilities-list {
      margin-bottom: 16px;
      flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    .subcapability-item{
      width: 32%;

      @media all and (max-width: 1080px){
        width: 50%;
      }

      @media all and (max-width: 500px){
        width: 100%;
      }
    }

      .sub-capability-card {
        margin-top: 15px;
      }
    }

    .supplier-charts {
      h2 {
        margin-bottom: 16px;
      }
    }
  }
}
