@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-capability {

  .content {
    padding: 16px;
    max-width: 860px;
    margin: 0 auto;
    a {
      color: black;
    }
    .bread-crumb {
      background: #FFF;

      display: flex;
      font-size: 13px;
      margin-bottom: 16px;
      flex-wrap: wrap;
      width: fit-content;
      padding: 8px 16px;
      border: 1px solid rgba($black, 0.2);
      border-radius: 4px;

      .bread, .slice {
        margin-right: 8px;
      }
    }

    .capabilities-list {
      margin-top: 16px;
      
      .sub-capability-card {
      
        margin-top: 15px;

      }
    }

    .capability-detail{

    }
  }

  .title-description{
    display: flex;
    justify-content: space-between;

    .title{
      width: 50%;

      h2{
        font-size: 24px;
        margin-bottom: 16px;
      }
    }

    .capability-description{
      color: rgba($black, 0.65);
    }

    .overall-crl{
      display: flex;
      flex-flow: column;
      width: 40%;
    }
  }
}