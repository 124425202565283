@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-buyer {

  .content {
    padding: 16px;
    a {
      color: black;

    }
    .buyer-card {
      margin-bottom: 16px;
      margin-top: 16px;
      .buyer-characteristic {
        .label {
          font-weight: bold;
        }

        .supplier {
          font-weight: bold;
          padding-right: 15px;
        }
      }
    }
    .capabilities {
      h2 {
        margin-bottom: 16px;
      }
    }
    .capabilities-list {
      margin-bottom: 16px;
      .sub-capability-card {
        margin-top: 15px;
      }
    }


    .buyer-charts {
      h2 {
        margin-bottom: 16px;
      }
    }
  }
}