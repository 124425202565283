@import '../../styles/colors.scss';

.kpi {
  border-radius: 8px;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  padding: 16px 24px;

  .object{
    background: $blue;
    line-height: 64px;
    text-align: center;
    color: $whitish;
    width: 64px;
    min-width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 24px;
  }

  .values{
    .kpi-value {
      color: $black;
      font-size: 42px;
      font-weight: 600;
      margin: 0;
    }
  
    .kpi-title {
      color: rgba($black, 0.65);
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      margin-top: 8px;
    }
  }

  @media all and (max-width: 960px){
    align-items: center;
    border-top: 4px solid $blue;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    flex-flow: column;
    justify-content: center;

    .object{
      height: 32px;
      min-width: 32px;
      width: 32px;
      display: none;
    }

    .values{
      .kpi-value {
        color: $black;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        margin: 0;
      }
    
      .kpi-title {
        color: rgba($black, 0.65);
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-top: 8px;
      }
    }
  }
}
