@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.footer {
   background: #FFF;
   display: flex;

   ul {
     display: flex;
     list-style: none;

     li {
       margin-right: 15px;
       
     }
   }
}