@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-buyers {
  max-width: $content-max-width;
  margin: 0 auto;

  .content {
    h2 {
      display: block;
      font-size: 36px;
      margin-bottom: 40px;
    }

    .buyers-list {
      .buyer-card {
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
        align-items: center;
        background: #fff;
        color: $black;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 16px;
        transition: transform 0.25s ease, box-shadow 0.25s ease;

        @media all and (max-width: 500px) {
          flex-wrap: wrap;
          flex-direction: column;
        }

        &:hover {
          transform: scale(1.02);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        }

        .buyer-name {
          align-items: center;
          display: flex;
          font-weight: 600;
          width: 30%;

          @media all and (max-width: 500px) {
            width: 100%;
          }

          .buyer-logo {
            background: darken($blue-lightest, 2);
            border-radius: 50%;
            display: block;
            height: 40px;
            margin-right: 16px;
            width: 40px;
          }
        }

        .capabilities-count {
          width: 30%;

          @media all and (max-width: 500px) {
            width: 100%;
            text-align: left;
            margin-top: 8px;
          }
        }

        .score {
          text-align: right;
          width: 30%;
          @media all and (max-width: 500px) {
            width: 100%;
            text-align: left;
            margin-top: 8px;
          }
        }
      }
    }

    input {
      font-size: 14px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      margin-top: 8px;
      border-radius: 4px;
      border: 1px solid rgba(7, 24, 64, 0.2);
      height: 32px;
      padding: 4px;
    }

    label {
      display: inline-block;
      margin: 1em 0;
    }
  }
}
