@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.96c47680.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/static/media/MaterialIcons-Regular.0509ab09.woff2) format("woff2"), url(/static/media/MaterialIcons-Regular.29b882f0.woff) format("woff"), url(/static/media/MaterialIcons-Regular.d120c85b.ttf) format("truetype"); }

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

* {
  box-sizing: border-box; }

html, body {
  background: #F8F9FC;
  font-family: 'Roboto', sans-serif; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #071840; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  text-decoration: none; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.app-header {
  display: flex;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10; }
  .app-header .header {
    align-items: center;
    background: #FFF;
    border-right: 1px solid #E9ECF0;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
    padding: 56px 56px 56px 0;
    position: relative;
    -webkit-transition: padding 0.35s ease;
    transition: padding 0.35s ease;
    z-index: 2; }
    .app-header .header .symbol {
      display: none; }
    @media all and (max-width: 960px) {
      .app-header .header {
        padding: 32px 0; } }
  .app-header .logo-arrow {
    align-items: center;
    display: flex;
    padding: 0 24px;
    justify-content: center; }
    .app-header .logo-arrow .subnav-closer {
      display: none;
      background: none;
      border: 1px solid #E9ECF0;
      border-bottom-left-radius: 24px;
      border-top-left-radius: 24px;
      cursor: pointer;
      padding: 4px 8px 4px 12px;
      position: absolute;
      top: 0;
      right: -1px;
      -webkit-transform: translateY(calc(56px - 25%));
              transform: translateY(calc(56px - 25%));
      -webkit-transition: padding 0.25s ease;
      transition: padding 0.25s ease;
      margin: 0; }
      .app-header .logo-arrow .subnav-closer:focus {
        outline: none; }
      .app-header .logo-arrow .subnav-closer:hover {
        padding: 4px 16px 4px 24px; }
  .app-header .logo {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    width: 110px; }
  .app-header .menu-opener {
    background: none;
    cursor: pointer;
    display: none;
    border: 0;
    padding: 0; }
    .app-header .menu-opener span {
      color: #73809d;
      font-size: 32px; }
    @media all and (max-width: 500px) {
      .app-header .menu-opener {
        display: inline-block; } }
  .app-header ul {
    display: flex;
    padding: 0;
    flex-flow: column;
    list-style: none;
    width: 100%; }
    .app-header ul li {
      padding: 24px 80px 24px 40px;
      position: relative;
      -webkit-transition: box-shadow 0.25s ease, background-color 0.25s ease;
      transition: box-shadow 0.25s ease, background-color 0.25s ease; }
      @media all and (max-width: 960px) {
        .app-header ul li {
          padding: 24px 32px; } }
      .app-header ul li:before {
        background: #1D55DC;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        -webkit-transition: width 0.25s ease;
        transition: width 0.25s ease;
        top: 0;
        width: 0; }
      .app-header ul li a {
        align-items: center;
        color: #071840;
        display: flex;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        -webkit-transition: color 0.25s ease;
        transition: color 0.25s ease; }
        .app-header ul li a .material-icons {
          color: #1D55DC;
          font-size: 24px;
          margin-right: 16px; }
      .app-header ul li.active {
        background: #FFFFFF;
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
        border-radius: 0 32px 32px 0; }
        @media all and (max-width: 960px) {
          .app-header ul li.active {
            border-radius: 0; } }
        .app-header ul li.active:before {
          width: 4px; }
        .app-header ul li.active a {
          color: #1D55DC;
          font-weight: 600; }
  .app-header .user-menu {
    padding: 0 0 0 40px; }
    .app-header .user-menu .anonymous-menu {
      align-items: center;
      display: flex;
      justify-content: space-between; }
    .app-header .user-menu .user-name {
      align-items: center;
      display: flex;
      justify-content: space-between; }
    .app-header .user-menu .user-avatar {
      border-radius: 50%;
      width: 40px; }
    .app-header .user-menu .user-notifications {
      background: #CD122B;
      border-radius: 50%;
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      line-height: 24px;
      text-align: center;
      width: 24px; }
  .app-header .menu-expander {
    align-items: center;
    background: #f1f3f9;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    -webkit-transition: -webkit-transform 0.25s ease;
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    height: 48px;
    min-height: 48px;
    width: 48px; }
    @media all and (max-width: 500px) {
      .app-header .menu-expander {
        display: none; } }
    .app-header .menu-expander span {
      color: #071840;
      -webkit-transition: -webkit-transform 0.25s ease;
      transition: -webkit-transform 0.25s ease;
      transition: transform 0.25s ease;
      transition: transform 0.25s ease, -webkit-transform 0.25s ease; }
    .app-header .menu-expander:focus {
      outline: none; }
    .app-header .menu-expander:hover {
      -webkit-transform: rotate(-180deg) scale(1.1);
              transform: rotate(-180deg) scale(1.1); }
      .app-header .menu-expander:hover span {
        -webkit-transform: scale(0.7);
                transform: scale(0.7); }
  .app-header .header.collapsed {
    padding: 56px 0 56px 0; }
    @media all and (max-width: 960px) {
      .app-header .header.collapsed {
        padding: 32px 0; } }
    .app-header .header.collapsed .logo {
      display: none; }
    .app-header .header.collapsed .symbol {
      display: inline-block; }
    .app-header .header.collapsed ul li {
      padding: 24px; }
      .app-header .header.collapsed ul li.active {
        border-radius: 0; }
      .app-header .header.collapsed ul li .material-icons {
        margin-right: 0; }
      .app-header .header.collapsed ul li .menu-link {
        display: none; }
    .app-header .header.collapsed .menu-expander {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
      .app-header .header.collapsed .menu-expander:hover {
        -webkit-transform: rotate(0deg) scale(1.1);
                transform: rotate(0deg) scale(1.1); }
        .app-header .header.collapsed .menu-expander:hover span {
          -webkit-transform: rotate(0deg) scale(0.7);
                  transform: rotate(0deg) scale(0.7); }
  @media all and (max-width: 720px) {
    .app-header {
      padding: 0; }
      .app-header .logo {
        display: none; }
      .app-header .symbol {
        display: inline-block !important; }
      .app-header ul li {
        padding: 24px; }
        .app-header ul li.active {
          border-radius: 0; }
        .app-header ul li .material-icons {
          margin-right: 0; }
        .app-header ul li .menu-link {
          display: none; }
      .app-header .menu-expander {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
        .app-header .menu-expander:hover {
          -webkit-transform: rotate(0deg) scale(1.1);
                  transform: rotate(0deg) scale(1.1); }
          .app-header .menu-expander:hover span {
            -webkit-transform: rotate(0deg) scale(0.7);
                    transform: rotate(0deg) scale(0.7); } }
  @media all and (max-width: 500px) {
    .app-header {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      flex-flow: row; }
      .app-header .header {
        flex-flow: row;
        height: auto;
        padding: 8px 20px;
        width: 100%; }
        .app-header .header .logo-arrow {
          padding: 0; }
        .app-header .header ul {
          background: #FFF;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
          left: 0;
          margin: 0;
          opacity: 0;
          position: fixed;
          top: 50px;
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
          -webkit-transition: opacity 0.25s ease, visibility 0.25s ease, -webkit-transform 0.25s ease;
          transition: opacity 0.25s ease, visibility 0.25s ease, -webkit-transform 0.25s ease;
          transition: opacity 0.25s ease, visibility 0.25s ease, transform 0.25s ease;
          transition: opacity 0.25s ease, visibility 0.25s ease, transform 0.25s ease, -webkit-transform 0.25s ease;
          visibility: hidden; }
          .app-header .header ul.menuOpen {
            opacity: 1;
            -webkit-transform: scale(1);
                    transform: scale(1);
            visibility: visible; }
          .app-header .header ul li {
            border-top: 1px solid #E9ECF0;
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .app-header .header ul li a {
              flex-flow: column; }
              .app-header .header ul li a .material-icons {
                margin-right: 0; }
            .app-header .header ul li.active {
              box-shadow: none; }
            .app-header .header ul li:before {
              display: none; }
            .app-header .header ul li .menu-link {
              display: inline-block; } }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.footer {
  background: #FFF;
  display: flex; }
  .footer ul {
    display: flex;
    list-style: none; }
    .footer ul li {
      margin-right: 15px; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-home h1 {
  margin: 1em 0; }

.page-home .header {
  text-align: right; }

.page-home .kpi-list {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  margin: 3em 0; }
  .page-home .kpi-list > * {
    width: 30%;
    margin-top: 15px; }
    @media all and (max-width: 820px) {
      .page-home .kpi-list > * {
        width: 100%; } }
  @media all and (max-width: 820px) {
    .page-home .kpi-list {
      flex-flow: column;
      margin-top: 0; } }
  @media (min-width: 470px) {
    .page-home .kpi-list {
      justify-content: space-between; } }

.capability-levels-chart {
  background-color: #fff;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px; }

.kpi {
  border-radius: 8px;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  padding: 16px 24px; }
  .kpi .object {
    background: #1D55DC;
    line-height: 64px;
    text-align: center;
    color: #FFF;
    width: 64px;
    min-width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 24px; }
  .kpi .values .kpi-value {
    color: #071840;
    font-size: 42px;
    font-weight: 600;
    margin: 0; }
  .kpi .values .kpi-title {
    color: rgba(7, 24, 64, 0.65);
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-top: 8px; }
  @media all and (max-width: 960px) {
    .kpi {
      align-items: center;
      border-top: 4px solid #1D55DC;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      flex-flow: column;
      justify-content: center; }
      .kpi .object {
        height: 32px;
        min-width: 32px;
        width: 32px;
        display: none; }
      .kpi .values .kpi-value {
        color: #071840;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        margin: 0; }
      .kpi .values .kpi-title {
        color: rgba(7, 24, 64, 0.65);
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-top: 8px; } }

.button-group {
  display: inline-flex;
  align-content: stretch;
  align-items: stretch;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1); }
  .button-group button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    background-color: #fff; }
    .button-group button:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .button-group button:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .button-group button:focus {
      outline: none; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-login .content {
  padding: 16px;
  max-width: 400px;
  margin: 0 auto;
  text-align: center; }
  .page-login .content h1 {
    margin-bottom: 36px; }
  .page-login .content h2 {
    margin-top: 36px;
    margin-bottom: 16px; }
  .page-login .content input {
    padding: 15px;
    border: 0;
    margin-bottom: 10px;
    width: 300px; }

.login-button {
  background-color: transparent;
  border: none; }

.login-image-button {
  width: 300px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  background: white; }

.login-button-email {
  background-color: white;
  border: none;
  padding: 15px;
  width: 300px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3); }

.page-not-found {
  padding: 100px;
  text-align: center;
  font-size: 32px; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-buyers {
  max-width: 1260px;
  margin: 0 auto; }
  .page-buyers .content h2 {
    display: block;
    font-size: 36px;
    margin-bottom: 40px; }
  .page-buyers .content .buyers-list .buyer-card {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    background: #fff;
    color: #071840;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 16px;
    -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
    transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
    transition: transform 0.25s ease, box-shadow 0.25s ease;
    transition: transform 0.25s ease, box-shadow 0.25s ease, -webkit-transform 0.25s ease; }
    @media all and (max-width: 500px) {
      .page-buyers .content .buyers-list .buyer-card {
        flex-wrap: wrap;
        flex-direction: column; } }
    .page-buyers .content .buyers-list .buyer-card:hover {
      -webkit-transform: scale(1.02);
              transform: scale(1.02);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); }
    .page-buyers .content .buyers-list .buyer-card .buyer-name {
      align-items: center;
      display: flex;
      font-weight: 600;
      width: 30%; }
      @media all and (max-width: 500px) {
        .page-buyers .content .buyers-list .buyer-card .buyer-name {
          width: 100%; } }
      .page-buyers .content .buyers-list .buyer-card .buyer-name .buyer-logo {
        background: #f1f3f9;
        border-radius: 50%;
        display: block;
        height: 40px;
        margin-right: 16px;
        width: 40px; }
    .page-buyers .content .buyers-list .buyer-card .capabilities-count {
      width: 30%; }
      @media all and (max-width: 500px) {
        .page-buyers .content .buyers-list .buyer-card .capabilities-count {
          width: 100%;
          text-align: left;
          margin-top: 8px; } }
    .page-buyers .content .buyers-list .buyer-card .score {
      text-align: right;
      width: 30%; }
      @media all and (max-width: 500px) {
        .page-buyers .content .buyers-list .buyer-card .score {
          width: 100%;
          text-align: left;
          margin-top: 8px; } }
  .page-buyers .content input {
    font-size: 14px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid rgba(7, 24, 64, 0.2);
    height: 32px;
    padding: 4px; }
  .page-buyers .content label {
    display: inline-block;
    margin: 1em 0; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-buyer .content {
  padding: 16px; }
  .page-buyer .content a {
    color: black; }
  .page-buyer .content .buyer-card {
    margin-bottom: 16px;
    margin-top: 16px; }
    .page-buyer .content .buyer-card .buyer-characteristic .label {
      font-weight: bold; }
    .page-buyer .content .buyer-card .buyer-characteristic .supplier {
      font-weight: bold;
      padding-right: 15px; }
  .page-buyer .content .capabilities h2 {
    margin-bottom: 16px; }
  .page-buyer .content .capabilities-list {
    margin-bottom: 16px; }
    .page-buyer .content .capabilities-list .sub-capability-card {
      margin-top: 15px; }
  .page-buyer .content .buyer-charts h2 {
    margin-bottom: 16px; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.sub-capability-card {
  background: #FFF;
  margin-bottom: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px; }
  .sub-capability-card .top-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .sub-capability-card .top-info .crl {
      padding: 16px 0;
      margin: 0; }
    .sub-capability-card .top-info .sub-count {
      display: inline-block;
      padding: 8px 24px;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      background: #1D55DC;
      border-radius: 24px;
      margin-right: 16px; }
  .sub-capability-card .sub-capability-supplier {
    padding: 16px 24px 0 0; }
    .sub-capability-card .sub-capability-supplier strong {
      color: #071840;
      font-size: 21px;
      font-weight: 600; }
  .sub-capability-card .capability-description {
    padding: 16px 0;
    color: rgba(7, 24, 64, 0.65);
    line-height: 1.5;
    font-size: 16px; }
  .sub-capability-card .capability-suppliers {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0; }
    .sub-capability-card .capability-suppliers .supplier {
      margin-right: 16px;
      font-weight: bold; }
  .sub-capability-card .actions-required {
    padding-top: 16px; }
  .sub-capability-card .bottomer .team {
    margin-bottom: 16px;
    margin-top: 16px; }

.crl {
  display: flex;
  padding: 0;
  justify-content: space-between;
  max-width: 300px; }
  .crl li {
    cursor: pointer;
    background: #FFF;
    border: 1px solid rgba(7, 24, 64, 0.25);
    margin-right: 4px;
    font-size: 14px;
    font-weight: 600;
    height: 24px;
    width: 24px; }
    .crl li.active {
      background: #1D55DC; }
  .crl li {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; }
    .crl li.active {
      color: white; }
    .crl li.selected {
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5); }

.crl-validations li {
  cursor: pointer;
  list-style: none;
  margin: 10px 0; }
  .crl-validations li input {
    margin-right: 8px; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-supplier .header {
  display: flex;
  justify-content: space-between; }

.page-supplier .content {
  padding: 16px; }
  .page-supplier .content a {
    color: black; }
  .page-supplier .content .supplier-card {
    margin-bottom: 16px;
    margin-top: 16px;
    max-width: 300px; }
    .page-supplier .content .supplier-card .supplier-characteristic .label {
      font-weight: bold; }
  .page-supplier .content .capabilities h2 {
    font-size: 32px;
    margin-bottom: 16px; }
  .page-supplier .content .capabilities-list {
    margin-bottom: 16px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between; }
    .page-supplier .content .capabilities-list .subcapability-item {
      width: 32%; }
      @media all and (max-width: 1080px) {
        .page-supplier .content .capabilities-list .subcapability-item {
          width: 50%; } }
      @media all and (max-width: 500px) {
        .page-supplier .content .capabilities-list .subcapability-item {
          width: 100%; } }
    .page-supplier .content .capabilities-list .sub-capability-card {
      margin-top: 15px; }
  .page-supplier .content .supplier-charts h2 {
    margin-bottom: 16px; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-suppliers {
  max-width: 1260px;
  margin: 0 auto; }
  .page-suppliers .content h2 {
    display: block;
    font-size: 36px;
    margin-bottom: 40px; }
  .page-suppliers .content .suppliers-list .supplier-card {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    background: #fff;
    color: #071840;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 16px;
    -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
    transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
    transition: transform 0.25s ease, box-shadow 0.25s ease;
    transition: transform 0.25s ease, box-shadow 0.25s ease, -webkit-transform 0.25s ease; }
    @media all and (max-width: 500px) {
      .page-suppliers .content .suppliers-list .supplier-card {
        flex-wrap: wrap;
        flex-direction: column; } }
    .page-suppliers .content .suppliers-list .supplier-card:hover {
      -webkit-transform: scale(1.02);
              transform: scale(1.02);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); }
    .page-suppliers .content .suppliers-list .supplier-card .supplier-name {
      align-items: center;
      display: flex;
      font-weight: 600;
      width: 60%; }
      @media all and (max-width: 500px) {
        .page-suppliers .content .suppliers-list .supplier-card .supplier-name {
          width: 100%; } }
      .page-suppliers .content .suppliers-list .supplier-card .supplier-name .supplier-logo {
        background: #f1f3f9;
        border-radius: 50%;
        display: block;
        height: 40px;
        margin-right: 16px;
        width: 40px;
        overflow: hidden; }
    .page-suppliers .content .suppliers-list .supplier-card .capabilities-count {
      width: 25%; }
      @media all and (max-width: 500px) {
        .page-suppliers .content .suppliers-list .supplier-card .capabilities-count {
          width: 100%;
          text-align: left;
          margin-top: 8px; } }
    .page-suppliers .content .suppliers-list .supplier-card .score {
      text-align: right;
      width: 15%; }
      @media all and (max-width: 500px) {
        .page-suppliers .content .suppliers-list .supplier-card .score {
          width: 100%;
          text-align: left;
          margin-top: 8px; } }
  .page-suppliers input {
    font-size: 14px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid rgba(7, 24, 64, 0.2);
    height: 32px;
    padding: 4px; }
  .page-suppliers label {
    display: inline-block;
    margin: 1em 0; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-capability .content {
  padding: 16px;
  max-width: 860px;
  margin: 0 auto; }
  .page-capability .content a {
    color: black; }
  .page-capability .content .bread-crumb {
    background: #FFF;
    display: flex;
    font-size: 13px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 16px;
    border: 1px solid rgba(7, 24, 64, 0.2);
    border-radius: 4px; }
    .page-capability .content .bread-crumb .bread, .page-capability .content .bread-crumb .slice {
      margin-right: 8px; }
  .page-capability .content .capabilities-list {
    margin-top: 16px; }
    .page-capability .content .capabilities-list .sub-capability-card {
      margin-top: 15px; }

.page-capability .title-description {
  display: flex;
  justify-content: space-between; }
  .page-capability .title-description .title {
    width: 50%; }
    .page-capability .title-description .title h2 {
      font-size: 24px;
      margin-bottom: 16px; }
  .page-capability .title-description .capability-description {
    color: rgba(7, 24, 64, 0.65); }
  .page-capability .title-description .overall-crl {
    display: flex;
    flex-flow: column;
    width: 40%; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.activity-stream {
  width: 100%; }
  .activity-stream .title {
    cursor: pointer;
    background: white;
    font-weight: bold;
    border-left: 1px solid rgba(7, 24, 64, 0.1);
    border-right: 1px solid rgba(7, 24, 64, 0.1);
    border-top: 1px solid rgba(7, 24, 64, 0.1);
    padding: 15px; }
  .activity-stream .content {
    display: none;
    padding-bottom: 15px; }
    .activity-stream .content.open {
      display: block; }
  .activity-stream .activity {
    background: #FFF;
    padding: 24px 40px;
    border: 1px solid rgba(7, 24, 64, 0.1);
    border-radius: 4px; }
    .activity-stream .activity.activity-level-up {
      background: transparent;
      color: black; }
    .activity-stream .activity .topper {
      display: flex;
      justify-content: space-between; }
  .activity-stream .image-text {
    align-items: center;
    display: flex;
    margin-bottom: 32px; }
    .activity-stream .image-text .user-image img {
      border-radius: 50%;
      width: 48px; }
    .activity-stream .image-text .text {
      width: 70%; }
    .activity-stream .image-text:last-of-type {
      margin-bottom: 0; }
    .activity-stream .image-text.alt {
      flex-flow: row-reverse; }
      .activity-stream .image-text.alt .right {
        margin: 0 16px 0 0;
        text-align: right; }
      .activity-stream .image-text.alt .date {
        text-align: right; }
      .activity-stream .image-text.alt .text {
        background: #1D55DC;
        border: 2px solid #1D55DC;
        color: #FFF;
        border-top-right-radius: 0;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin: 0 0 0 auto; }
        .activity-stream .image-text.alt .text strong {
          color: #FFF; }
  .activity-stream .input-user .text {
    margin-top: 16px;
    margin-bottom: 16px; }
    .activity-stream .input-user .text textarea {
      font-size: 16px;
      width: 100%;
      height: 100px;
      margin-bottom: 16px;
      border: 1px solid rgba(7, 24, 64, 0.1);
      border-radius: 4px;
      padding: 16px;
      color: rgba(7, 24, 64, 0.7);
      width: 100%;
      resize: none; }
      .activity-stream .input-user .text textarea:focus {
        outline: none; }
    .activity-stream .input-user .text button {
      margin-left: auto;
      display: inline-block;
      background: #1D55DC;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      border: none;
      padding: 16px 24px;
      border-radius: 4px;
      color: white; }
      .activity-stream .input-user .text button:focus {
        outline: none; }
  .activity-stream .controls {
    display: flex;
    justify-content: flex-end; }
    .activity-stream .controls button {
      background: #FFF;
      border: 2px solid #1D55DC;
      cursor: pointer;
      padding: 12px 24px;
      color: #1D55DC;
      font-weight: 600;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center; }
      .activity-stream .controls button span {
        font-size: 18px; }
  .activity-stream .activity .right {
    margin: 0 0 0 16px;
    width: 100%; }
    .activity-stream .activity .right .date {
      color: rgba(7, 24, 64, 0.5);
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px; }
  .activity-stream .activity .text {
    border: 2px solid rgba(7, 24, 64, 0.1);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 16px;
    color: rgba(7, 24, 64, 0.7);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .activity-stream .activity .text strong {
      color: #071840;
      display: block;
      padding-bottom: 8px; }
    .activity-stream .activity .text p {
      margin: 0; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-sub-capability {
  max-width: 1260px;
  margin: 0 auto; }
  .page-sub-capability .top-items {
    display: flex;
    justify-content: space-between; }
    @media all and (max-width: 1180px) {
      .page-sub-capability .top-items {
        flex-flow: column-reverse;
        width: 100%; }
        .page-sub-capability .top-items .right {
          display: flex;
          flex-flow: column;
          width: 100%;
          justify-content: space-between; } }
  .page-sub-capability .hmap {
    border: 1px solid rgba(7, 24, 64, 0.1);
    background: #FFF;
    width: 100%; }
    .page-sub-capability .hmap .apexcharts-menu-icon {
      -webkit-transform: translate(-10px, 10px);
              transform: translate(-10px, 10px); }
    .page-sub-capability .hmap text:first-of-type {
      display: none; }
    .page-sub-capability .hmap g text:first-of-type {
      display: block; }
  .page-sub-capability .content {
    max-width: 860px;
    margin: 0 auto; }
    .page-sub-capability .content .bread-crumb {
      display: flex;
      font-size: 13px;
      margin-bottom: 16px;
      flex-wrap: wrap; }
      .page-sub-capability .content .bread-crumb .bread,
      .page-sub-capability .content .bread-crumb .slice {
        margin-right: 8px; }
    .page-sub-capability .content h3 {
      border: 1px solid rgba(7, 24, 64, 0.1);
      border-bottom: 0;
      font-size: 21px;
      margin: 40px 0 0;
      background: #FFF;
      padding: 16px 24px;
      width: 100%; }
    .page-sub-capability .content .evidences {
      background: #FFF;
      padding: 16px 24px;
      border: 1px solid rgba(7, 24, 64, 0.1);
      border-radius: 4px;
      width: 100%; }
      .page-sub-capability .content .evidences .evidence {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid rgba(7, 24, 64, 0.1); }
        .page-sub-capability .content .evidences .evidence:first-of-type {
          padding-top: 0; }
        .page-sub-capability .content .evidences .evidence:last-of-type {
          border-bottom: 0;
          padding-bottom: 0; }
        .page-sub-capability .content .evidences .evidence .level {
          color: #1D55DC;
          font-size: 18px;
          font-weight: 600;
          width: 15%; }
        .page-sub-capability .content .evidences .evidence .filename {
          align-items: center;
          display: flex; }
          .page-sub-capability .content .evidences .evidence .filename span {
            font-size: 21px; }
          .page-sub-capability .content .evidences .evidence .filename a {
            color: #1D55DC; }
  .page-sub-capability .capability-level {
    position: relative; }
  .page-sub-capability .submenu-crl {
    background: #FFF;
    border: 1px solid rgba(7, 24, 64, 0.1);
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(7, 24, 64, 0.25);
    -webkit-transform: translateY(0);
            transform: translateY(0);
    padding: 16px;
    position: absolute;
    width: 100%; }
    .page-sub-capability .submenu-crl b {
      color: #1D55DC;
      font-size: 16px;
      margin-bottom: 8px;
      display: block; }
    .page-sub-capability .submenu-crl p {
      margin: 0;
      font-size: 14px;
      padding: 8px;
      background: rgba(29, 85, 220, 0.1); }
    .page-sub-capability .submenu-crl ul {
      padding-left: 0;
      margin: 0; }
      .page-sub-capability .submenu-crl ul li {
        border-bottom: 1px solid rgba(7, 24, 64, 0.1);
        margin: 0;
        padding: 8px 0;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .page-sub-capability .submenu-crl ul li:last-of-type {
          padding-bottom: 0;
          border-bottom: 0; }
  .page-sub-capability .element-wr {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    margin-top: 64px; }
    @media all and (max-width: 1180px) {
      .page-sub-capability .element-wr {
        margin-top: 24px; } }
    .page-sub-capability .element-wr .element-one {
      width: 55%; }
      @media all and (max-width: 1180px) {
        .page-sub-capability .element-wr .element-one {
          width: 100%; }
          .page-sub-capability .element-wr .element-one h2 {
            max-width: 460px; } }
      .page-sub-capability .element-wr .element-one .capability-description {
        color: rgba(7, 24, 64, 0.6);
        margin: 16px 0; }
        @media all and (max-width: 1180px) {
          .page-sub-capability .element-wr .element-one .capability-description {
            max-width: 460px; } }
      .page-sub-capability .element-wr .element-one .bottom-stuff {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        width: 70%; }
        .page-sub-capability .element-wr .element-one .bottom-stuff a {
          color: #1D55DC;
          display: block;
          font-weight: 600;
          margin-top: 4px; }
    .page-sub-capability .element-wr .actions-general {
      background: #FFF;
      padding: 24px 40px;
      border: 1px solid rgba(7, 24, 64, 0.1);
      border-radius: 4px; }
      .page-sub-capability .element-wr .actions-general h4 {
        display: block;
        font-size: 21px; }
      .page-sub-capability .element-wr .actions-general .actions-required {
        margin-bottom: 16px; }
      .page-sub-capability .element-wr .actions-general .action-items {
        display: flex;
        flex-flow: column; }
        .page-sub-capability .element-wr .actions-general .action-items .action button {
          background: none;
          border: 2px solid #1D55DC;
          border-radius: 4px;
          cursor: pointer;
          color: #1D55DC;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 8px;
          padding: 12px;
          width: 100%; }
        .page-sub-capability .element-wr .actions-general .action-items .action:first-of-type button {
          background: #1D55DC;
          border: 1px solid #1D55DC;
          color: #FFF; }
      @media all and (max-width: 1180px) {
        .page-sub-capability .element-wr .actions-general {
          padding: 16px 24px;
          margin-bottom: 24px; }
          .page-sub-capability .element-wr .actions-general .action-items {
            margin: 0; }
            .page-sub-capability .element-wr .actions-general .action-items .button-group {
              box-shadow: none; } }
  .page-sub-capability .action-items {
    margin: 1em 0; }
    .page-sub-capability .action-items .button-group {
      box-shadow: none; }
      .page-sub-capability .action-items .button-group button {
        background: #FFF;
        border: 2px solid #1D55DC;
        cursor: pointer;
        padding: 12px;
        color: #1D55DC;
        font-weight: 600;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        white-space: nowrap; }
        .page-sub-capability .action-items .button-group button:first-of-type {
          margin-left: 0; }


h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.page-capabilities .title-filter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }
  .page-capabilities .title-filter .material-icons {
    align-items: center;
    background: #e6eaf4;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    -webkit-transition: -webkit-transform 0.25s ease;
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
    height: 48px;
    min-height: 48px;
    width: 48px; }
    .page-capabilities .title-filter .material-icons:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
      .page-capabilities .title-filter .material-icons:hover span {
        -webkit-transform: scale(0.7);
                transform: scale(0.7); }

.page-capabilities .capabilities-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.page-capabilities .capability-card-unit {
  width: 32%; }
  @media all and (max-width: 1370px) {
    .page-capabilities .capability-card-unit {
      width: 48%; } }
  @media all and (max-width: 720px) {
    .page-capabilities .capability-card-unit {
      width: 100%; } }

.page-capabilities .content h2 {
  display: block;
  font-size: 36px; }

.page-capabilities .content .content-list {
  display: flex;
  flex-flow: column; }
  .page-capabilities .content .content-list a {
    color: #071840; }

h1, h2, h3, h4 {
  margin: 0; }

.app-body {
  display: flex; }

.page {
  max-width: 1260px;
  margin: 0 auto 0 0;
  padding: 56px 20px;
  padding-left: 80px;
  -webkit-transition: padding-left 0.25s ease-out;
  transition: padding-left 0.25s ease-out;
  width: 100%; }
  .page.subheader-open {
    padding-left: 640px; }
  @media all and (max-width: 960px) {
    .page {
      padding: 32px 20px;
      padding-left: 20px; } }
  @media all and (max-width: 500px) {
    .page {
      padding: 80px 20px;
      padding-left: 20px; } }

.page-title {
  color: #071840;
  font-size: 36px; }

.capability-card {
  background: #FFF;
  margin-bottom: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
  -webkit-transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: box-shadow 0.25s ease, -webkit-transform 0.25s ease;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  transition: transform 0.25s ease, box-shadow 0.25s ease, -webkit-transform 0.25s ease; }
  .capability-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1.025);
            transform: scale(1.025); }
  .capability-card .top-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .capability-card .top-info .crl {
      padding: 16px 0;
      margin: 0; }
      .capability-card .top-info .crl li {
        background: #FFF;
        border: 1px solid rgba(7, 24, 64, 0.25);
        margin-right: 4px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        height: 24px;
        position: relative;
        width: 24px; }
        .capability-card .top-info .crl li.active {
          background: #1D55DC; }
        .capability-card .top-info .crl li:after {
          background: rgba(7, 24, 64, 0.25);
          content: '';
          height: 2px;
          position: absolute;
          right: 0;
          -webkit-transform: translateX(100%);
                  transform: translateX(100%);
          width: 6px; }
        .capability-card .top-info .crl li:last-of-type {
          margin-right: 0; }
          .capability-card .top-info .crl li:last-of-type:after {
            display: none; }
        @media all and (max-width: 1370px) {
          .capability-card .top-info .crl li {
            display: none; }
            .capability-card .top-info .crl li.active {
              display: block; }
              .capability-card .top-info .crl li.active:after {
                display: none; } }
    .capability-card .top-info .sub-count {
      display: inline-block;
      padding: 8px 24px;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      background: #1D55DC;
      border-radius: 24px;
      margin-right: 16px; }
  .capability-card .capability-title {
    padding: 16px 24px 0 0; }
    .capability-card .capability-title strong {
      color: #071840;
      font-size: 21px;
      font-weight: 600; }
  .capability-card .capability-description {
    padding: 16px 0;
    color: rgba(7, 24, 64, 0.65);
    line-height: 1.5;
    font-size: 16px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    padding-bottom: 0;
    overflow: hidden;
    margin-bottom: 16px; }
  .capability-card .capability-suppliers {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    padding: 16px 0;
    min-height: 110px; }
    .capability-card .capability-suppliers .supplier {
      margin-right: 16px;
      font-weight: bold; }
  .capability-card .actions-required {
    padding-top: 16px; }

.capability-filters {
  padding: 24px 0;
  display: flex;
  justify-content: space-between; }
  @media all and (max-width: 1080px) {
    .capability-filters {
      display: none; }
      .capability-filters .filter-search .label {
        width: 100%; }
      .capability-filters .filter-items {
        flex-direction: column; }
        .capability-filters .filter-items label {
          margin: 0;
          margin-left: 0; }
      .capability-filters.open {
        display: block; } }
  .capability-filters .filter-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center; }
    @media all and (max-width: 1080px) {
      .capability-filters .filter-items {
        flex-flow: row;
        flex-wrap: wrap; } }
    .capability-filters .filter-items label {
      margin-bottom: 24px;
      width: 15%;
      margin-left: 20px; }
      .capability-filters .filter-items label strong {
        font-size: 12px;
        text-transform: uppercase; }
      .capability-filters .filter-items label input,
      .capability-filters .filter-items label select {
        font-size: 14px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid rgba(7, 24, 64, 0.2);
        height: 32px;
        padding: 4px; }
      @media all and (max-width: 1080px) {
        .capability-filters .filter-items label {
          width: 100%;
          margin-left: 0; } }
  .capability-filters .filter-search {
    display: flex;
    align-items: center; }
    .capability-filters .filter-search label {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0;
      margin-bottom: 24px;
      width: 15%;
      align-items: center; }
    .capability-filters .filter-search input {
      margin-left: 0;
      font-size: 14px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      margin-top: 8px;
      border-radius: 4px;
      border: 1px solid rgba(7, 24, 64, 0.2);
      height: 32px;
      padding: 4px; }
  .capability-filters label {
    display: flex;
    flex-flow: column; }



