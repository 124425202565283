@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

// .sub-capability-card {
//   border: 1px solid;
//   padding: 15px;

//   .topper {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 16px;

//     .sub-capability-supplier {
//       font-weight: bold;
//     }

//     .sub-capability-date {
//       font-size: 13px;
//     }
//   }

//   .bottomer {
//     .team-title {
//       font-weight: bold;
//     }

//     .supplier-title {
//       font-weight: bold;
//       margin-top: 15px;
//     }
//   }
// }

.sub-capability-card {
  background: #FFF;
  margin-bottom: 24px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 24px;

  .top-info{
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .crl{
      padding: 16px 0;
      margin: 0;

      
    }

    .sub-count{
      display: inline-block;
      padding: 8px 24px;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      background: $blue;
      border-radius: 24px;
      margin-right: 16px;
    }
  }

  .sub-capability-supplier {
    padding: 16px 24px 0 0;

    strong{
      color: $black;
      font-size: 21px;
      font-weight: 600;
    }
  }

  .capability-description {
    padding: 16px 0;
    color: rgba($black, 0.65);
    line-height: 1.5;
    font-size: 16px;
  }

  .capability-suppliers {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;

    .supplier {
      margin-right: 16px;
      font-weight: bold;
    }
  }

  .actions-required {
    padding-top: 16px;
  }

  .bottomer {
    .team {
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
}