@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.activity-stream {
  width: 100%;
  .title {
    cursor: pointer;
    background: white;
    font-weight: bold;
    border-left: 1px solid rgba($black, 0.1);
    border-right: 1px solid rgba($black, 0.1);
    border-top: 1px solid rgba($black, 0.1);
    padding: 15px;
  }

  .content {
    display: none;
    padding-bottom: 15px;

    &.open {
      display: block;
    }
  }
  .activity {
    background: #FFF;
    padding: 24px 40px;
    border: 1px solid rgba($black, 0.1);
    border-radius: 4px;

    &.activity-level-up {
      background: transparent;
      color: black;
    }

    .topper {
      display: flex;
      justify-content: space-between;
    }
  }


  .image-text{
    align-items: center;
    display: flex;
    margin-bottom: 32px;

    .user-image {
      img {
        border-radius: 50%;
        width: 48px;
      }
    }

    .text{
      width: 70%;
    }

    &:last-of-type{
      margin-bottom: 0;
    }

    &.alt{
      flex-flow: row-reverse;

      .right{
        margin: 0 16px 0 0;
        text-align: right;
      }

      .date{
        text-align: right;
      }

      .text{
        background: $blue;
        border: 2px solid $blue;
        color: $whitish;
        border-top-right-radius: 0;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin: 0 0 0 auto;

        strong{
          color: $whitish;
        }
      }
    }
  }

  .input-user {
    

    .text {
      margin-top: 16px;
      margin-bottom: 16px;
      textarea {
        font-size: 16px;
        width: 100%;
        height: 100px;
        margin-bottom: 16px;
        border: 1px solid rgba($black, 0.1);
        border-radius: 4px;
        padding: 16px;
        color: rgba($black, 0.7);
        width: 100%;
        resize: none;

        &:focus{
          outline: none;
        }
      }

      button {
        margin-left: auto;
        display: inline-block;
        background: $blue;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border: none;
        padding: 16px 24px;
        border-radius: 4px;
        color: white;
        &:focus{
          outline: none;
        }
      }
    }

  }

  .controls {
    display: flex;
    justify-content: flex-end;

    button {
      background: #FFF;
      border: 2px solid $blue;
      cursor: pointer;
      padding: 12px 24px;
      color: $blue;
      font-weight: 600;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;

      span{
        font-size: 18px;
      }
    }
  }

  .activity {

    .right{
      margin: 0 0 0 16px;
      width: 100%;

      .date{
        color: rgba($black, 0.5);
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
    .text {
      border: 2px solid rgba($black, 0.1);
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      padding: 16px;
      color: rgba($black, 0.7);
      width: fit-content;

      strong{
        color: $black;
        display: block;
        padding-bottom: 8px;
      }

      p{
        margin: 0;
      }
    }
  }
}