@import '../../styles/colors.scss';
.crl {
  display: flex;
  padding: 0;
  justify-content: space-between;
  max-width: 300px;

  li {
    cursor: pointer;
    background: $whitish;
    border: 1px solid rgba($black, 0.25);
    margin-right: 4px;
    font-size: 14px;
    font-weight: 600;
    height: 24px;
    width: 24px;

    &.active {
      background: $blue;
    }
  }
  li {
    list-style-type: none;

    display: flex;

    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.active {
      color: white;
    }

    &.selected {
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
.crl-validations {
  li {
    cursor: pointer;
    list-style: none;
    margin: 10px 0;

    input {
      margin-right: 8px;
    }
  }
}
