@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-capabilities {

  .title-filter{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .material-icons{
     align-items: center;
     background: darken($blue-lightest,5);
     border: none;
     border-radius: 50%;
     cursor: pointer;
     display: flex;
     justify-content: center;
     transition: transform 0.25s ease;
     height: 48px;
     min-height: 48px;
     width: 48px;

     &:hover{
       transform: scale(1.1);

       span{
        transform: scale(0.7);
       }
     }
    }
  }
  .capabilities-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .capability-card-unit{
    //min-width: 400px;
    width: 32%;

    @media all and (max-width: 1370px){
      width: 48%;
    }

    @media all and (max-width: 720px){
      width: 100%;
    }
  }
  
  .content {
    h2{
      display: block;
      font-size: 36px;
    }
    
    .content-list {
      display: flex;
      flex-flow: column;

      a {
        color: $black;
      }
    }
  }
}