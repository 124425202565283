@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-sub-capability {
  max-width: $content-max-width;
  margin: 0 auto;

  .top-items{
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 1180px){
      flex-flow: column-reverse;
      width: 100%;

      .right{
        display: flex;
        flex-flow: column;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .hmap{
    border: 1px solid rgba($black, 0.1);
    background: #FFF;
    width: 100%;

    .apexcharts-menu-icon{
      transform: translate(-10px,10px);
    }

    text{
      &:first-of-type{
        display: none;
      }
    }

    g text:first-of-type{
      display: block;
    }
  }

  .content {
    max-width: 860px;
    margin: 0 auto;

    .bread-crumb {
      display: flex;
      font-size: 13px;
      margin-bottom: 16px;
      flex-wrap: wrap;

      .bread,
      .slice {
        margin-right: 8px;
      }
    }

    h3 {
        border: 1px solid rgba($black, 0.1);
        border-bottom: 0;
        font-size: 21px;
        margin: 40px 0 0;
        background: #FFF;
        padding: 16px 24px;
        width: 100%;
    }

    .evidences {
      background: #FFF;
      padding: 16px 24px;
      border: 1px solid rgba($black, 0.1);
      border-radius: 4px;
      width: 100%;

      .evidence {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid rgba($black, 0.1);

        &:first-of-type{
          padding-top: 0;
        }

        &:last-of-type{
          border-bottom: 0;
          padding-bottom: 0;
        }

        .level{
          color: $blue;
          font-size: 18px;
          font-weight: 600;
          width: 15%;
        }

        .filename{
          align-items: center;
          display: flex;

          span{
            font-size: 21px;
          }

          a{
            color: $blue;
          }
        }
      }
    }
  }


  .capability-level{
    position: relative;
  }

  .submenu-crl{
    background: #FFF;
    border: 1px solid rgba($black, 0.1);
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba($black, 0.25);
    transform: translateY(0);
    padding: 16px;
    position: absolute;
    width: 100%;

    b{
      color: $blue;
      font-size: 16px;
      margin-bottom: 8px;
      display: block;
    }
    p{
      margin: 0;
      font-size: 14px;
      padding: 8px;
      background: rgba($blue,0.1);
    }

    ul{
      padding-left: 0;
      margin: 0;

      li{
        border-bottom: 1px solid rgba($black, 0.1);
        margin: 0;
        padding: 8px 0;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-of-type{
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
  .element-wr{
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    margin-top: 64px;

    @media all and (max-width: 1180px){
      margin-top: 24px;
    }

    .element-one{
      width: 55%;


      @media all and (max-width: 1180px){
        width: 100%;

        h2{
          max-width: 460px;
        }
      }

      .capability-description{
        color: rgba($black, 0.6);
        margin: 16px 0;

        @media all and (max-width: 1180px){
          max-width: 460px;
        }
      }

      .bottom-stuff{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        width: 70%;

        a{
          color: $blue;
          display: block;
          font-weight: 600;
          margin-top: 4px;
        }
      }
    }
    .actions-general{
      background: #FFF;
      padding: 24px 40px;
      border: 1px solid rgba(7, 24, 64, 0.1);
      border-radius: 4px;

      h4{
        display: block;
        font-size: 21px;
      }

      .actions-required{
        margin-bottom: 16px;
      }

      .action-items{
        display: flex;
        flex-flow: column;

        .action{
          button{
            background: none;
            border: 2px solid $blue;
            border-radius: 4px;
            cursor: pointer;
            color: $blue;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px;
            padding: 12px;
            width: 100%;
          }

          &:first-of-type{
            button{
              background: $blue;
              border: 1px solid $blue;
              color: $whitish;
            }
          }
        }
      }

      @media all and (max-width: 1180px){
         padding: 16px 24px;
         margin-bottom: 24px;

         .action-items{
           margin: 0;

           .button-group{
             box-shadow: none;
           }
         }
      }
    }
  }
  .action-items {
    margin: 1em 0;

    .button-group{
      box-shadow: none;

      button{
        background: #FFF;
        border: 2px solid $blue;
        cursor: pointer;
        padding: 12px;
        color: #1D55DC;
        font-weight: 600;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        white-space: nowrap;

        &:first-of-type{
          margin-left: 0;
        }
      }
    }
  }
}
