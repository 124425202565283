@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.capability-card {
  background: #FFF;
  margin-bottom: 24px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 24px;
  transition: transform 0.25s ease, box-shadow 0.25s ease;

  &:hover{
    box-shadow: 0 4px 16px rgba(0,0,0,0.1);
    transform: scale(1.025);
  }

  .top-info{
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .crl{
      padding: 16px 0;
      margin: 0;

      li{
        background: $whitish;
        border: 1px solid rgba($black, 0.25);
        margin-right: 4px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        height: 24px;
        position: relative;
        width: 24px;

        &.active{
          background: $blue;
        }

        &:after{
          background: rgba($black, 0.25);
          content: '';
          height: 2px;
          position: absolute;
          right: 0;
          transform: translateX(100%);
          width: 6px;
        }
        &:last-of-type{
          margin-right: 0;
          &:after{
            display: none;
          }
        }

        @media all and (max-width: 1370px){
          display: none;
          &.active{
            display: block;
            &:after{
              display: none;
            }
          }
        }
      }
    }

    .sub-count{
      display: inline-block;
      padding: 8px 24px;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      background: $blue;
      border-radius: 24px;
      margin-right: 16px;
    }
  }

  .capability-title {
    padding: 16px 24px 0 0;

    strong{
      color: $black;
      font-size: 21px;
      font-weight: 600;
    }
  }

  .capability-description {
    padding: 16px 0;
    color: rgba($black, 0.65);
    line-height: 1.5;
    font-size: 16px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    padding-bottom: 0;
    overflow: hidden;
    margin-bottom: 16px;
  }

  .capability-suppliers {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    padding: 16px 0;
    min-height: 110px;

    .supplier {
      margin-right: 16px;
      font-weight: bold;
    }
  }

  .actions-required {
    padding-top: 16px;
  }
}