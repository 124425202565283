@import './colors.scss';

$content-small-max-width: 720px;
$content-normal-max-width: 1080px;
$content-max-width: 1260px;
$container-max-width: 1760px;

h1,h2,h3,h4{
    margin: 0;
}
.app-body{
    display: flex;
}

.page{
    max-width: $content-max-width;
    margin: 0 auto 0 0;
    padding: 56px 20px;
    padding-left: 80px;
    transition: padding-left 0.25s ease-out;
    width: 100%;

    &.subheader-open{
        padding-left: 640px;
    }

    @media all and (max-width: 960px){
        padding: 32px 20px;
        padding-left: 20px;
    }

    @media all and (max-width: 500px){
        padding: 80px 20px;
        padding-left: 20px;
    }
}

.page-title{
    color: $black;
    font-size: 36px;
}