@import '../../styles/colors.scss';

.capability-filters {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;;

  @media all and (max-width: 1080px){
  
    display: none;
    .filter-search {
      .label {
        width: 100%;
      }
    }

    .filter-items {
      flex-direction: column;
      label {
        margin: 0;
        margin-left: 0;
      }
    }

    &.open{
      display: block;
    }
  }

  // @media all and (max-width: 500px){
  //   transform: translateX(100%);
  //   padding: 24px 0;
  //   position: fixed;
  //   background: #FFF;
  //   box-shadow: -4px 0 4px rgba(0,0,0,0.1);
  //   padding: 24px;
  //   width: 50%;
  //   right: 0;
  //   z-index: 1;
  //   transition: transform 0.35s ease;
  //   height: 100%;
  //   top: 0;

  //   &.open{
  //     transform: translateX(0);
  //   }
  // }

  .filter-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    @media all and (max-width: 1080px){
      flex-flow: row;
      flex-wrap: wrap;
    }

    label {
      margin-bottom: 24px;
      width: 15%;
      margin-left: 20px;

      strong {
        font-size: 12px;
        text-transform: uppercase;
      }

      input,
      select {
        font-size: 14px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid rgba($black, 0.2);
        height: 32px;
        padding: 4px;
      }

      @media all and (max-width: 1080px){
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .filter-search {
      display: flex;
      align-items: center;

    label {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0;
      margin-bottom: 24px;
      width: 15%;
      align-items: center;
    }

    input {
      margin-left: 0;
      font-size: 14px;
      font-family: 'Roboto', Helvetica, Arial, sans-serif;
      margin-top: 8px;
      border-radius: 4px;
      border: 1px solid rgba(7, 24, 64, 0.2);
      height: 32px;
      padding: 4px;
    }
  }

  label {
    display: flex;
    flex-flow: column;
  }
}
