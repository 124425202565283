@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-login {

  .content {
    padding: 16px;

    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    h1 {
      margin-bottom: 36px;
    }

    h2 {
      margin-top: 36px;
      margin-bottom: 16px;
    }

    input {
      padding: 15px;
      border: 0;
      margin-bottom: 10px;
      width: 300px;
    }
  }
}

.login-button {
  background-color: transparent;
  border: none;
}

.login-image-button {
  width: 300px;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);

  cursor: pointer;
  background: white;
}


.login-button-email {
  background-color: white;
  border: none;
  padding: 15px;
  width: 300px;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
}