@import '../../styles/layout.scss';
@import '../../styles/colors.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

.app-header {
  display: flex;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;

  .header {
    align-items: center;
    background: $whitish;
    border-right: 1px solid #E9ECF0;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
    padding: 56px 56px 56px 0;
    position: relative;
    transition: padding 0.35s ease;
    z-index: 2;

    .symbol{
      display: none;
    }

    @media all and (max-width: 960px){
      padding: 32px 0;
    }
  }

  .logo-arrow{
    align-items: center;
    display: flex;
    //margin: 0 0 40px 40px;
    padding: 0 24px;
    justify-content: center;

    .subnav-closer{
      display: none;
      background: none;
      border: 1px solid #E9ECF0;
      border-bottom-left-radius: 24px;
      border-top-left-radius: 24px;
      cursor: pointer;
      padding: 4px 8px 4px 12px;
      position: absolute;
      top: 0;
      right: -1px;
      transform: translateY(calc(56px - 25%));
      transition: padding 0.25s ease;
      margin: 0;

      &:focus{
        outline: none;
      }

      &:hover{
        padding: 4px 16px 4px 24px;
      }
    }
  }

  .logo{
    transform: translateY(50%);
    width: 110px;
  }

  .menu-opener{
    background: none;
    cursor: pointer;
    display: none;
    border: 0;
    padding: 0;

    span{
      color: $grey-bluish;
      font-size: 32px;
    }

    @media all and (max-width: 500px){
      display: inline-block;
    }
  }

   ul {
     display: flex;
     padding: 0;
     flex-flow: column;
     list-style: none;
     width: 100%;

     li{
       padding: 24px 80px 24px 40px;
       position: relative;
       transition: box-shadow 0.25s ease, background-color 0.25s ease;

       @media all and (max-width: 960px){
         padding: 24px 32px;
       }

       &:before{
        background: $blue;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        transition: width 0.25s ease;
        top: 0;
        width: 0;
      }

       a{
         align-items: center;
         color: $black;
         display: flex;
         font-size: 15px;
         font-weight: 500;
         text-transform: uppercase;
         transition: color 0.25s ease;

         .material-icons{
           color: $blue;
           font-size: 24px;
           margin-right: 16px;
         }
       }

       &.active{
        background: #FFFFFF;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.10);
        border-radius: 0 32px 32px 0;

        @media all and (max-width: 960px){
          border-radius: 0;
        }

        &:before{
          width: 4px;
        }

        a{
          color: $blue;
          font-weight: 600;
        }
       }
     }
   }

   .user-menu {
     padding: 0 0 0 40px;

     .anonymous-menu{
      align-items: center;
      display: flex;
      justify-content: space-between;
     }

     .user-name{
       align-items: center;
       display: flex;
       justify-content: space-between;
     }

     .user-avatar{
       border-radius: 50%;
       width: 40px;
     }

     .user-notifications{
       background: $red;
       border-radius: 50%;
       color: $whitish;
       font-size: 12px;
       font-weight: 600;
       height: 24px;
       line-height: 24px;
       text-align: center;
       width: 24px;
     }
   }

   .menu-expander{
     align-items: center;
     background: darken($blue-lightest,2);
     border: none;
     border-radius: 50%;
     cursor: pointer;
     display: flex;
     justify-content: center;
     transition: transform 0.25s ease;
     transform: rotate(-180deg);
     height: 48px;
     min-height: 48px;
     width: 48px;

    @media all and (max-width: 500px){
      display: none;
    } 

     span{
       color: $black;
       transition: transform 0.25s ease;
      }
      
      &:focus{
        outline: none;
      }

     &:hover{
       transform: rotate(-180deg) scale(1.1);

       span{
        transform: scale(0.7);
       }
     }
   }

   .header.collapsed{
    padding: 56px 0 56px 0;

    @media all and (max-width: 960px){
      padding: 32px 0;
    }

    .logo{
      display: none;
    }

    .symbol{
      display: inline-block;
    }

    ul li{
      padding: 24px;
      &.active{
        border-radius: 0;
      }

      .material-icons{
        margin-right: 0;
      }

      .menu-link{
        display: none;
      }
    }

    .menu-expander{
      transform: rotate(0deg);

      &:hover{
        transform: rotate(0deg) scale(1.1);
 
        span{
         transform: rotate(0deg) scale(0.7);
        }
      }
    }
 }

 @media all and (max-width: 720px){
  padding: 0;

  .logo{
    display: none;
  }

  .symbol{
    display: inline-block!important;
  }

  ul li{
    padding: 24px;
    &.active{
      border-radius: 0;
    }

    .material-icons{
      margin-right: 0;
    }

    .menu-link{
      display: none;
    }
  }

  .menu-expander{
    transform: rotate(0deg);

    &:hover{
      transform: rotate(0deg) scale(1.1);

      span{
       transform: rotate(0deg) scale(0.7);
      }
    }
  }
 }

 @media all and (max-width: 500px){
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  flex-flow: row;

  .header{
    flex-flow: row;
    height: auto;
    padding: 8px 20px;
    width: 100%;

    .logo-arrow{
      padding: 0;
    }

    ul{
      background: #FFF;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      left: 0;
      margin: 0;
      opacity: 0;
      position: fixed;
      top: 50px;
      transform: scale(0.9);
      transition: opacity 0.25s ease, visibility 0.25s ease, transform 0.25s ease;
      visibility: hidden;

    &.menuOpen{
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }

    li{
      border-top: 1px solid #E9ECF0;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      a{
        flex-flow: column;

        .material-icons{
          margin-right: 0;
        }
      }

      &.active{
        box-shadow: none;
      }

      &:before{
        display: none;
      }

      .menu-link{
        display: inline-block;
      }
    }
    }
  }
 }
}