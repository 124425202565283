@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-home {
  h1 {
    margin: 1em 0;
  }

  .header {
    text-align: right;
  }

  .kpi-list {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    margin: 3em 0;

    & > * {
      width: 30%;
      margin-top: 15px;

      @media all and (max-width: 820px){
        width: 100%;
      }
    }


    @media all and (max-width: 820px){
      flex-flow: column;    
      margin-top: 0;
    }

    @media (min-width: 470px){
      justify-content: space-between;
    }
  }
}
