.button-group {
  display: inline-flex;
  align-content: stretch;
  align-items: stretch;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    background-color: #fff;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:focus {
      outline: none;
    }
  }
}
